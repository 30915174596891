.card-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.applicant-data-form .label {
    width: 8rem;
}

.applicant-data-form {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.applicant-data-form h4:nth-child(2) {
    width: calc(100% - 10rem);
}


.applicant-card-form .photo {
    width: 6rem;
    height: intrinsic;
    margin-right: 1rem;
    border: solid 1px #999;
}

.applicant-card-form {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}

@media (max-width: 560px) {
    .card-title {
        display: block!important;
        justify-content: initial!important;
        text-align: center;
    }
}



.applicant-photo {
    width: 8rem;
    border: solid 1px #999;
    border-radius: 1rem;
}