.desktop-background {
  display: none;
}


@media only screen and (min-width: 600px) {
  .desktop-background {
    display: block;
    width: 100vw;
    width: 50vw;
    height: 100vh;
    position: fixed;
    z-index: -1;
    object-fit: cover;
  }

  .mobile-view {
    width: 50%;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    background: rgba(255, 255, 255, .75);
    backdrop-filter: blur(1rem);
    overflow-y: auto;
  }
}

.desktop-background .carousel-item .slide-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.desktop-background .carousel-item .slide-image-blurred {
  filter: blur(1rem);
  transition: filter 0.3s ease;
}

.desktop-background .carousel-item {
  position: absolute;
  width: 100%;
  height: 100%;
  border: none;
  top: 0;
  left: 100%;
  overflow: hidden;
}

.desktop-background .carousel-item.active {
  left: 0;
  transition: all 0.85s ease-in-out;
}