.imageSchool {
    width: 24rem;
    height: 8rem;
}

.imageLayoutTopBar {
    display: flex;
    justify-content: space-around;
    height: 6.9rem;
}

.imageLayoutBody {
    padding-top: 1rem;
}