:root {
    --ul-border-color: #aaa;
}

.body1 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    letter-spacing: 0.15px;
    /* Neutral/neutral-500 */
}

.body2 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.15px;
}

.body2-text {
    /* body2 */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: justify;
    letter-spacing: 0.15px;

    /* Default/Black */

    color: #161616;


    /* Inside auto layout */

    flex: none;
    order: 1;
    flex-grow: 0;
}

.h5 {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
}

.h6 {
    /* H6 */
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.15px;

    /* Default/Black */

    color: #161616;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}

.button-primary {
    background: #FF5117;
    border-radius: 100px;
    height: 36px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 18px;
    gap: 4px;
    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.button-primary:hover {
    background: #FF8A64;
}



.button-text-primary {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
}

.button-text-dark {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
}

.button-text-neutral {
    /* Button */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;

    /* Neutral/neutral-300 */
    color: #62616E;
}

.button-outlined {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 18px;
    gap: 4px;
    /* border: 1px solid #151327; */
    border: 1px solid #FF5117;
    border-radius: 100px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    height: 36px;
    box-sizing: border-box;
    /* color:  #151327; */
    color: #FF5117;
}

.button-outlined:disabled {
    color: #62616E;
    border-color: #62616E;
}


.button-without-outlined {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 18px;
    gap: 4px;

    height: 36px;

    border-radius: 100px;
    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}

.button-neutral {
    /* Auto layout */

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 18px;
    gap: 4px;

    height: 36px;

    /* Neutral/neutral-100 */

    background: #E1E1E1;
    border-radius: 100px;

    /* Inside auto layout */

    flex: none;
    order: 2;
    align-self: stretch;
    flex-grow: 0;
}

.button-neutral:hover {
    background-color: #E1E1E1;
}

.min-padding {
    padding: 1rem;
}

.min-padding-border {
    padding-left: 1rem;
    padding-right: 1rem;
}

.footer {
    background-color: #E1E1E1;
    height: 28vh;
    margin-top: 2rem;
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
}

.input-text {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.15px;
}

.helperText {
    /* helperText */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.4px;

    /* Default/Black */

    color: #161616;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.input-field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 16px;
    height: 55px;
}

.min-space {
    padding-top: 1rem;
}

.min-space-button {
    padding-top: 0.5rem;
}

.max-padding-bottom {
    padding-bottom: 2rem;
}

.radio-button {
    margin: 0;
    margin-bottom: 0.5rem;
    height: 51px;

    /* Neutral/neutral-500 */

    border: 1px solid var(--ul-border-color);
    border-radius: .75rem;

    /* Inside auto layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 8px;
}

.arrow-back {
    /* color:#151327; */
    width: 16px;
    height: 16px;
}

.disclaimer {
    height: 28px;
    /* width: 20.5rem; */
    /* helperText */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.4px;

    /* Primary/primary-500 */

    color: #FF5117;


    /* Inside auto layout */

    flex: none;
    order: 0;
    align-self: stretch;
    flex-grow: 0;
}

.validation-error {
    font-size: 12px;
    color: red;
}

.checkbox-input-primary {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #161616;
    line-height: 19px;
    letter-spacing: 0.15px;
}

.checkbox-input-dark {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    color: #161616;
    ;
    line-height: 19px;
    letter-spacing: 0.15px;
}

.photoImage {
    display: flex;
    justify-content: space-around;
    height: 6.9rem;
}

.photoImageDimensionLayout {
    margin-top: 19px;
    margin-bottom: 30px;
}

.photoImageDimension {
    margin-top: 19px;
    width: 6rem;
    height: 8rem;
}

.margin-height-auto {
    height: auto;
}

.input-document {
    /* inputLabel */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    letter-spacing: 0.15px;
}

/* Color de borde de TextField */
.MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
    border-color: var(--ul-border-color) !important;
    border-radius: .5rem!important;
}

.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: var(--ul-border-color) !important;
}

.MuiInputLabel-root {
    /* color: var(--ul-border-color) !important; */
    background: #fff;
}

.Mui-focused .MuiInputLabel-root {
    /* color: var(--ul-border-color) !important; */
    background: #fff;
}

.download-link {
    color: #F37021;
    cursor: pointer;
}

.text-primary {
    color: #F37021;
}

/* h4.MuiTypography-root {
    font-weight: 400!important;
} */