.imageWelcome{
    width: 100%;
    height: auto;
    left: 19px;
    top: 108.59px;
}

.imageWelcomeWithoutTop{
    position: absolute;
    width: 368px;
    height: 303px;
    left: 19px;
    top: 83.59px;
}