.layoutContainer{
    font-weight: bold !important;
    background-color: #ffffff;
    width:100%;
    height:fit-content;
    /* position: sticky; */
    position: sticky;
    top: 0;
    z-index: 10;
}

.layoutImage{
    vertical-align: Bottom;
    height: 5rem;
    width: 11rem;
}

.iconTextPI{
    color: white;
    font-size: 12px;
    cursor: pointer;
}

.layoutTitle{
    margin-left: 1vh;
}

.imageSchool{
    width: 24rem;
    height: 8rem;
}