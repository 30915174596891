.range-10 {
    width: 10rem;
}

.range-auto {
    width: calc(100% - 3em);
}
.user-avatar-preview {
    font-family: Roboto;
}
.user-avatar-preview::before {
    content: attr(data-content);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.user-avatar-preview-z-n1::before {
    z-index: -1;
}

.user-avatar-controls-container {
    max-width: 20rem;
    margin: 0 auto;
}

.crop-info {
    position: absolute;
    padding: 1rem;
    background: rgba(0, 0, 0, .7);
    color: #fff;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    text-align: center;
    align-items: center;
}

.picture-placeholder {
    position: absolute;
    top: 0;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    margin: 10px;
    user-select: none;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-select: none;
    -ms-user-select: none;
    pointer-events: none;
    background-image: url('./PersonLine.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
}

.picture-placeholder::before {
    content: attr(data-content);
    position: absolute;
    bottom: .5rem;
    right: .5rem;
    font-size: .7rem;
}