.stepper-container {
    /* padding: 1rem; */
    padding: 0;
    border-radius: 0px 0px 0px 0px;
    position: absolute;
    z-index: 10;
    width: 100%;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    justify-content: space-between;
}

/* .stepperIcon{
    margin-top: 0.5rem;
    color: black;
}

.icon-stepper{
    background-color: #62616E;
}

.check-icon{
    width: 1.7rem;
    height: 1.7rem;
}

.progress-number{
    font-family: Roboto;
    font-size: 0.7rem;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0.4000000059604645px;
    text-align: center;

} */

.stepper-grid {
    height: 5rem;
    z-index: 10;
    position: sticky;
    top: 5rem;
    display:
    flex;
    align-items: flex-start;
    background: white;
}

.stepper-container .step-container {
    width: 100%;
    position: relative;
    text-align: center;
}

.stepper-container .step-indicator {
    background: #e1e1e1;
    color: #9e9e9e;
    cursor: no-drop;
    display: inline-flex;
    width: 2.4rem;
    height: 2.4rem;
    border-radius: 50%;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
}

.step-container .step-indicator.step-prev-completed {
    background: #fbd3ba;
    cursor: pointer;
}

.stepper-container .step-indicator.step-current {
    background: #ff5117;
    color: #ffffff;
    cursor: default;
}

.stepper-container .step-indicator::before, .stepper-container .step-indicator.step-current::after {
    content: "";
    position: absolute;
    width: 100%;
    background: #efefef;
    height: .3rem;
    z-index: -1;
}

.stepper-container .step-indicator.step-current::after {
    right: 0;
    width: 50%;
}

.stepper-container .step-indicator.step-completed::before, .stepper-container .step-indicator.step-current::before {
    background: #fbd3ba!important;
}

.stepper-container .step-indicator.step-completed {
    background: #fbd3ba;
    color: #000;
    cursor: pointer;
}

.stepper-container .step-indicator.step-completed:hover {
    background: #f79f6a;
}